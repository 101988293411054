import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../providers/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';
import {MatSnackBar} from '@angular/material';
import { materialize } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  model: any = {};
  username: any;
  password: any;
  loading = false;
  captchaToken = '';
  error = '';

  constructor(public translate: TranslateService, public auth: AuthService, public router: Router, public snackBar: MatSnackBar, public aRouter: ActivatedRoute) {
    if (this.auth.isAuthenticated()) {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      const role = user.user.role;
      if (role === 1 || role === 3) {
        this.router.navigate(['cockpit']);
      } else if (role === 5 || role === 12  || role === 14) {
        this.router.navigate(['cockpit']);
      } else if (role === 7 ) {
        this.router.navigate(['finance-list']);
      } else if (role === 9 ) {
        this.router.navigate(['reports']);
      } else if (role === 19 || role === 35 ) {
        this.router.navigate(['dr-booking']);
      } else if (role === 77  ) {
        this.router.navigate(['price-maker/table']);
      } else if (role === 8 ) {
        this.router.navigate(['expedition']);
      } else if (role === 2 ) {
        this.router.navigate(['new-delivery-request']);
      }
    } else {
      this.aRouter.queryParams.subscribe( params => {
        if (params.token) {
          const tkn = params.token;
          console.log(params);
          const userJs = JSON.parse(params.user);
          localStorage.setItem('currentUser', JSON.stringify({ user: userJs , token: tkn }));
          const user = JSON.parse(localStorage.getItem('currentUser'));
          const role = user.user.role;
          if (role === 1 || role === 3) {
            this.router.navigate(['cockpit']);
          } else if (role === 5 || role === 12  || role === 14) {
            this.router.navigate(['cockpit']);
          } else if (role === 7 ) {
            this.router.navigate(['finance-list']);
          } else if (role === 9 ) {
            this.router.navigate(['reports']);
          } else if (role === 19 || role === 35 ) {
            this.router.navigate(['dr-booking']);
          } else if (role === 77  ) {
            this.router.navigate(['price-maker/table']);
          } else if (role === 8 ) {
            this.router.navigate(['expedition']);
          } else if (role === 2 ) {
            this.router.navigate(['new-delivery-request']);
          }
        }
      });
    }
  }

  resolved(captchaResponse: string) {
      // console.log(captchaResponse);
      this.captchaToken = captchaResponse;
  }

  ngOnInit() {
  }

  login() {
    this.loading = true;
    if (false) {
      this.auth.ssoLogin(this.username).subscribe( result => {
        console.log(result);
        window.location.href = result['url'];
      });
    } else {
      this.auth.login(this.username, this.password, this.captchaToken).subscribe(result => {
        if (result === 'cap') {
          this.snackBar.open('Captcha não verificado', 'Fechar', {
            duration: 2000,
          });
          this.error = 'Username or password is incorrect';
          this.loading = false;
          return;
        } else if (result === 'cred') {
          // login failed
          this.snackBar.open('Usuário ou senha invalidos', 'Fechar', {
            duration: 2000,
          });
          // this.router.navigate(['login']);
          return false;
        } else {
          // login successful
          const user = JSON.parse(localStorage.getItem('currentUser'));
          const role = user.user.role;
          if (role === 1 || role === 3) {
            this.router.navigate(['cockpit']);
          } else if (role === 5 || role === 12  || role === 14) {
            this.router.navigate(['cockpit']);
          } else if (role === 7 ) {
            this.router.navigate(['finance-list']);
          } else if (role === 19 || role === 35 ) {
            this.router.navigate(['dr-booking']);
          } else if (role === 77  ) {
            this.router.navigate(['price-maker/table']);
          } else if (role === 9 ) {
            this.router.navigate(['reports']);
          } else if (role === 2 ) {
            this.router.navigate(['new-delivery-request']);
          }

          return true;
        }
      }, error => {
        // login failed
        this.snackBar.open('Usuário ou senha invalidos', 'Fechar', {
          duration: 2000,
        });
      });
    }
  }

  chageLanguage() {
    this.translate.use('pt').subscribe((data) => {
      // console.log(data);
    });
  }

  forgotPassword() {
    if (!this.username) {
      this.snackBar.open('Email ausente', 'Ok', { duration: 2000 });
      return;
    }
    this.loading = true;
    this.auth.sendEmail(this.username, this.captchaToken)
        .subscribe(result => {
          // console.log(result)
          this.snackBar.open('Email enviado', 'Fechar', {
            duration: 2000,
          });
          if (result === false) {
            // login failed
            this.snackBar.open('Erro no envio de email', 'Fechar', {
              duration: 2000,
            });
            this.error = 'Username or password is incorrect';
            return false;
          }
        });
  }

}
