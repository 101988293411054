export const environment = {
  VERSION: require('../../package.json').version,
  production: false,
  websocketUrl: 'https://a.qa.br.aware.blue:1344/',
  apiUrl: 'https://a.qa.br.aware.blue:1344/',
  apiKey: 'IDwsF4mHYL8wG7i31QaJH3cDGw8R9NZ31O0Jfw1e',
};

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
