import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../providers/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';
import { MatSnackBar } from '@angular/material';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(translate, auth, router, snackBar, aRouter) {
        var _this = this;
        this.translate = translate;
        this.auth = auth;
        this.router = router;
        this.snackBar = snackBar;
        this.aRouter = aRouter;
        this.model = {};
        this.loading = false;
        this.captchaToken = '';
        this.error = '';
        if (this.auth.isAuthenticated()) {
            var user = JSON.parse(localStorage.getItem('currentUser'));
            var role = user.user.role;
            if (role === 1 || role === 3) {
                this.router.navigate(['cockpit']);
            }
            else if (role === 5 || role === 12 || role === 14) {
                this.router.navigate(['cockpit']);
            }
            else if (role === 7) {
                this.router.navigate(['finance-list']);
            }
            else if (role === 9) {
                this.router.navigate(['reports']);
            }
            else if (role === 19 || role === 35) {
                this.router.navigate(['dr-booking']);
            }
            else if (role === 77) {
                this.router.navigate(['price-maker/table']);
            }
            else if (role === 8) {
                this.router.navigate(['expedition']);
            }
            else if (role === 2) {
                this.router.navigate(['new-delivery-request']);
            }
        }
        else {
            this.aRouter.queryParams.subscribe(function (params) {
                if (params.token) {
                    var tkn = params.token;
                    console.log(params);
                    var userJs = JSON.parse(params.user);
                    localStorage.setItem('currentUser', JSON.stringify({ user: userJs, token: tkn }));
                    var user = JSON.parse(localStorage.getItem('currentUser'));
                    var role = user.user.role;
                    if (role === 1 || role === 3) {
                        _this.router.navigate(['cockpit']);
                    }
                    else if (role === 5 || role === 12 || role === 14) {
                        _this.router.navigate(['cockpit']);
                    }
                    else if (role === 7) {
                        _this.router.navigate(['finance-list']);
                    }
                    else if (role === 9) {
                        _this.router.navigate(['reports']);
                    }
                    else if (role === 19 || role === 35) {
                        _this.router.navigate(['dr-booking']);
                    }
                    else if (role === 77) {
                        _this.router.navigate(['price-maker/table']);
                    }
                    else if (role === 8) {
                        _this.router.navigate(['expedition']);
                    }
                    else if (role === 2) {
                        _this.router.navigate(['new-delivery-request']);
                    }
                }
            });
        }
    }
    LoginComponent.prototype.resolved = function (captchaResponse) {
        // console.log(captchaResponse);
        this.captchaToken = captchaResponse;
    };
    LoginComponent.prototype.ngOnInit = function () {
    };
    LoginComponent.prototype.login = function () {
        var _this = this;
        this.loading = true;
        if (false) {
            this.auth.ssoLogin(this.username).subscribe(function (result) {
                console.log(result);
                window.location.href = result['url'];
            });
        }
        else {
            this.auth.login(this.username, this.password, this.captchaToken).subscribe(function (result) {
                if (result === 'cap') {
                    _this.snackBar.open('Captcha não verificado', 'Fechar', {
                        duration: 2000,
                    });
                    _this.error = 'Username or password is incorrect';
                    _this.loading = false;
                    return;
                }
                else if (result === 'cred') {
                    // login failed
                    _this.snackBar.open('Usuário ou senha invalidos', 'Fechar', {
                        duration: 2000,
                    });
                    // this.router.navigate(['login']);
                    return false;
                }
                else {
                    // login successful
                    var user = JSON.parse(localStorage.getItem('currentUser'));
                    var role = user.user.role;
                    if (role === 1 || role === 3) {
                        _this.router.navigate(['cockpit']);
                    }
                    else if (role === 5 || role === 12 || role === 14) {
                        _this.router.navigate(['cockpit']);
                    }
                    else if (role === 7) {
                        _this.router.navigate(['finance-list']);
                    }
                    else if (role === 19 || role === 35) {
                        _this.router.navigate(['dr-booking']);
                    }
                    else if (role === 77) {
                        _this.router.navigate(['price-maker/table']);
                    }
                    else if (role === 9) {
                        _this.router.navigate(['reports']);
                    }
                    else if (role === 2) {
                        _this.router.navigate(['new-delivery-request']);
                    }
                    return true;
                }
            }, function (error) {
                // login failed
                _this.snackBar.open('Usuário ou senha invalidos', 'Fechar', {
                    duration: 2000,
                });
            });
        }
    };
    LoginComponent.prototype.chageLanguage = function () {
        this.translate.use('pt').subscribe(function (data) {
            // console.log(data);
        });
    };
    LoginComponent.prototype.forgotPassword = function () {
        var _this = this;
        if (!this.username) {
            this.snackBar.open('Email ausente', 'Ok', { duration: 2000 });
            return;
        }
        this.loading = true;
        this.auth.sendEmail(this.username, this.captchaToken)
            .subscribe(function (result) {
            // console.log(result)
            _this.snackBar.open('Email enviado', 'Fechar', {
                duration: 2000,
            });
            if (result === false) {
                // login failed
                _this.snackBar.open('Erro no envio de email', 'Fechar', {
                    duration: 2000,
                });
                _this.error = 'Username or password is incorrect';
                return false;
            }
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
